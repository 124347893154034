<template>
  <!-- 选择支付方式 默认选中在线支付 -->
  <el-dialog class="payDiage" :visible="dialogVisible" :destroy-on-close="true" :show-close="true"
    :append-to-body="true" :before-close="handleBeforeClose" width="640px">
    <!-- dialog_close.svg -->
    <div class="pay-continer">
      <div class="pay-type">
        <div :class="{ payActive: payType == 1 }" @click="handleChangePayInfo(1)">
          <img v-if="payType == 1" src="~@/assets/svg/pay/online_select.svg" alt="">
          <img v-else src="~@/assets/svg/pay/online.svg" alt="">
          <span>在线支付</span>
        </div>
        <div :class="{ payActive: payType == 2 }" @click="handleChangePayInfo(2)">
          <img v-if="payType == 2" src="~@/assets/svg/pay/public_select.svg" alt="">
          <img v-else src="~@/assets/svg/pay/public.svg" alt="">
          <span>对公转账</span>
        </div>
      </div>
      <div class="pay-splice-line"></div>
    <!-- 二维码区域 在线支付-->
    <div v-if="payType == 1" class="qr-code-container">
      <div class="pay-amount">实付金额：<span><i>￥</i>{{payGoodInfo.price}}</span></div>
      <div class="special-pay-tip" v-if="payGoodInfo.is_sum == 3">（年付:{{payGoodInfo.per_price}}元×12个月）</div>
      <div class="qr-code-wrap">
        <div v-if="wxPayQrCode && wxPayQrCode !== ''" class="qr-code">
          <div class="scande_border">
            <vue-qrcode :value="wxPayQrCode" :width="130" :height="130" margin="auto" style="margin: 10px;" />
          </div>
          <p style="margin-top: 22px">
            <img src="@/assets/image/wxpic.png" alt="" style="margin: 2px 7px 0 0" />
            <span style="font-weight: 400;color: rgba(0, 0, 0, 0.85);font-size: 12px;">微信支付</span>
          </p>
        </div>
        <div v-if="aliPayQrCode && aliPayQrCode !== ''" class="qr-code">
          <div class="scande_border">
            <iframe id="bdIframe" :src="aliPayQrCode" frameborder="0" width="130" height="130" style="margin: 10px"
              transform="scale(.5, .5)" scrolling="no" />
          </div>
          <p style="margin-top: 22px">
            <img src="@/assets/image/alipaypic.png" alt="" style="margin: 2px 7px 0 0" />
            <span style="font-weight: 400;color: rgba(0, 0, 0, 0.85);font-size: 12px;">支付宝支付</span>
          </p>
        </div>
      </div>
      <div class="agree">
        <span>支付即视为您同意</span><br>
        <a class="link-type" :href="userProtocol" target="_blank">《PlayList会员用户协议》</a>
      </div>
      <div class="qr-tip">* {{isRetail ? '' : '支付宝签约'}}支付成功后在
        <span v-if="isRetail" @click="goMineOrder">个人中心-我的订单</span>
        <span v-else @click="goMine">个人中心-我的会员</span>
      中查看{{isRetail ? '' : '已购会员'}}</div>
    </div>
    <!-- 对公转账 -->
    <div v-if="payType == 2" class="public-container">
      <div class="pay-amount">实付金额：<span><i>￥</i>{{payGoodInfo.price}}</span></div>
      <div class="special-pay-tip" v-if="payGoodInfo.is_sum == 3">（年付:{{payGoodInfo.per_price}}元×12个月）</div>
      <el-descriptions :column="1">
        <el-descriptions-item label="账户名称">北京数音未来科技有限公司</el-descriptions-item>
        <el-descriptions-item label="公司账号">110060635018800064856</el-descriptions-item>
        <el-descriptions-item label="开户行">交通银行股份有限公司北京三元支行</el-descriptions-item>
        <!--<el-descriptions-item label="法定代表人">卢汉铁</el-descriptions-item>-->
        <el-descriptions-item label="联系电话">13522352793</el-descriptions-item>
      </el-descriptions>

      <el-form ref="publicForm" :model="publicForm" :rules="publicRules">
        <el-form-item label="付款回执单" prop="receipt">
          <SingleImageUpload v-model="publicForm.receipt" accept="image/*" :isclear="isclear" />
        </el-form-item>
        <el-form-item label="付款备注" prop="remark">
          <el-input v-model="publicForm.remark" type="textarea" placeholder="请输入付款备注"/>
        </el-form-item>
      </el-form>

      <p class="tip">
        完成线下转账/汇款后，请 <span onclick="qimoChatClick();">联系客服</span> 我们将及时为您开通会员权限
      </p>
      <div class="submit-btn-container">
        <!-- style="width: 96px; height: 32px; line-height: 32px;" -->
        <el-button type="primary" @click="handleSubmitPublicInfo">提交</el-button>
      </div>
    </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  fetchOrderPayByPublicPay,
  submitPublicPayReceipt,
  submitRetailPublicPay
} from '@/api/package'
import { mapGetters } from 'vuex'
import SingleImageUpload from '@/views/package/components/UploadPublicImage.vue'
import VueQrcode from '@/components/VueQrcode'
import { userProtocol } from '@/config'

export default {
  data() {
    return {
      userProtocol,
      payType: 1, // 支付方式
      isclear: false, // 是否清空付款回执单
      publicForm: {
        receipt: '',
        remark: ''
      },
      publicRules: {
        receipt: [
          { required: true, message: '请上传付款回执单', trigger: 'change' }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },

    }
  },
  props: {
    isRetail: {
      type: Boolean,
      default: false
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    payGoodInfo: {
      type: Object,
      default: () => {}
    },
    wxPayQrCode: {
      type: String,
      default: ''
    },
    aliPayQrCode: {
      type: String,
      default: ''
    }
  },
  components: {
    SingleImageUpload,
    VueQrcode
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
  },
  methods: {
    goMine(){
      this.$router.push({
        path: `/member/center/equity`
      })
    },
    goMineOrder(){
      this.$router.push({
        path: `/member/center/order`
      })
    },
    handleBeforeClose() {
      this.payType = 1
      this.$emit('close')
    },
    handleChangePayInfo(type) {
      this.payType = type
      if (type == 2 && !this.isRetail) {
        this.getPublicInfo()
      }
    },
    getPublicInfo() {
      const params = {
        goods_id: this.payGoodInfo.goods_id,
        order_id: this.order_id || '',
        share_token: window.localStorage.getItem('share_token')
          ? window.localStorage.getItem('share_token')
          : ''
      }
      fetchOrderPayByPublicPay(params).then((res) => {
        const {
          data: { order_no }
        } = res
        this.order_no = order_no
      })
    },
     /**
     * 处理提交信息
     * 2021-12-23 10:14:29
     * @author SnowRock
     */
    handleSubmitPublicInfo() {
      this.$refs.publicForm.validate(async (valid) => {
        if (!valid) return
        const { receipt: receipt_path, remark } = this.publicForm
        const params = {
          receipt_path,
          remark
        }
        try {
          if(this.isRetail) await submitRetailPublicPay({...params, order_id: this.payGoodInfo.order_id})
          if(!this.isRetail) await submitPublicPayReceipt({...params, order_no: this.order_no})
          this.$message.success('提交成功')
          this.$refs.publicForm.resetFields() // 清空付款回执单跟付款备注
          this.isclear = true
          this.payType = 1
          this.$emit('publicSubmit')

        } catch (e) {
          console.log(e)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.payDiage{
  /deep/ {
    .el-dialog {
      position: relative;
      border-radius: 5px;
      .el-dialog__header{
        display: none;
      }
    }
    .el-dialog__body{
      padding: 40px 64px 36px 38px;
    }
  }
  .pay-continer{
    display: flex;
    .pay-type{
      margin-top: 32px;
      div{
        cursor: pointer;
        width: 116px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        margin-bottom: 20px;
        img{
          margin-right: 10px;
        }
      }
      .payActive{
        border: 1px solid #E52A0D;
      }
    }
    .pay-splice-line{
      width: 1px;
      height: 270px;
      border-right: 1px solid #DCDFE6;
      margin: 24px 19px 0 30px;
    }

  }
}
.public-container, .qr-code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .pay-amount{
    color: #333333;
    span{
      font-size: 30px;
      color: #E52A0D;
      font-weight: bold;
      i{
        font-style: normal;
        font-size: 14px;
      }
    }
  }
  .special-pay-tip{
    margin-top: 12px;
    font-size: 14px;
    color: #999999;
  }
}
.qr-code-container {
  .agree{
    text-align: center;
    font-size: 12px;
    color: #999;
    line-height: 16px;
  }
  .qr-tip{
    margin-top: 30px;
    color: #999999;
    font-size: 12px;
    span{
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .qr-code-wrap {
    display: flex;
  }
  .qr-code {
    padding: 20px;
    text-align: center;
    .scande_border {
      width: 150px;
      height: 150px;
      border: 1px solid rgba(195, 195, 205, .6);
      border-radius: 2px;

      vue-qrcode {
        margin: auto;
        line-height: 130px;
      }
    }
    .code {
      width: 150px;
      height: 150px;
      background: #d8d8d8;
      border: 1px solid #979797;
      margin-bottom: 26px;
    }

    p {
      display: flex;
      justify-content: center;

      img {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.public-container {
  width: 380px;
  /deep/ {
    .el-descriptions{
      font-size: 12px;
      padding: 20px 0 0 20px;
      .el-descriptions-item__label{
        color: #999;
        width: 78px;
      }
      .el-descriptions-item__content {
        color: #333333;
        font-weight: bold;
      }
      .el-descriptions-item__cell{
        padding-bottom: 6px;
      }
    }

    .el-form{
      margin-left: 20px;
      width: 100%;
      .el-form-item{
        margin-bottom: 10px;
        .el-form-item__label{
          font-size: 12px;
          text-align: left;
          color: #999;
          width: 98px;
        }
        .el-form-item__content{
          line-height: 0;
          .el-textarea {
            width: 248px;
            font-size: 12px;
            .el-textarea__inner{
              width: 248px;
              height: 54px;
              background: #F2F3F5;
              border: none;
            }
          }
        }
        .el-form-item__error{
          left: 98px;
        }

      }

    }
  }

  .tip {
    // margin-top: 10px;
    font-size: 12px;
    color: #999999;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.submit-btn-container {
  width: 400px;
  text-align: center;
  margin-top: 30px;

  .el-button {
    width: 96px;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }
}
</style>
